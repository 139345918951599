import styled from "styled-components"

export const BannerWrapper = styled("div")({
  position: "relative",
  ".slider": {
    height: "500px",
  },
  ".ant-carousel .slick-dots": {
    position: "relative",
  },
})

export const HeaderTitle = styled.div`
  color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
  width: 100%;
  max-width: 900px;
  > h5 {
    color: #fff;
    position: relative;
    margin: 0;
    font-weight: 400;
    font-size: calc((48 / 16) * 1rem);
    letter-spacing: calc((-0.5 / 16) * 1rem);
    line-height: calc((69 / 16) * 1rem);
    text-transform: uppercase;
    &::after {
      content: "";
      display: inline-block;
      width: 0;
      height: 0;
      border-left: 100px solid transparent;
      border-right: 100px solid transparent;
      border-bottom: 6px solid #d7272d;
      position: absolute;
      left: 50%;
      bottom: -10px;
      -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
    }
  }

  .company-info-header-description {
    margin-top: 2em;
  }
`

export const SectionWrapper = styled("div")({
  "> div:nth-child(2)": {
    backgroundColor: "#fff",
  },
  ".section-title p": {
    color: "#1b467f",
  },
})
