import React, { ReactChild, ReactNode } from "react"
import { Carousel } from "antd"

import MainLayout from "../../Layouts/layoutLanding"

import { BannerWrapper, HeaderTitle, SectionWrapper } from "./styled"

import BannerImage from "../../assets/images/hero-banner-image.png"
import BannerOverlay from "../../assets/images/Overlay-Carousel-BG.png"

interface Props {
  pageTitle: string
  headerTitle: string | ReactChild | ReactNode
  children: ReactChild | ReactNode
  bannerImageSrc?: string
  bannerImages?: any[]
  headerDescription?: string | ReactChild | ReactNode
  pageContext: any
}

const CustomCompanyInfo: React.FC<Props> = ({
  pageTitle,
  headerTitle,
  children,
  bannerImageSrc,
  bannerImages,
  headerDescription,
  pageContext,
}) => {
  return (
    <MainLayout title={pageTitle} pageContext={pageContext}>
      <BannerWrapper>
        <div className="slider image">
          {bannerImages ? (
            <div className="brand-carousel-container carousel-dots-custom">
              <Carousel autoplay autoplaySpeed={5000}>
                {bannerImages &&
                  bannerImages.map((item, i) => (
                    <div className="slider image">
                      <img
                        src={BannerOverlay}
                        alt="Overlay"
                        className="overlay-banner"
                      />
                      <img src={item.url} alt="Carousel Image" />
                    </div>
                  ))}
              </Carousel>
            </div>
          ) : (
            <>
              <img
                src={BannerOverlay}
                alt="Overlay"
                className="overlay-banner"
              />
              <img
                src={bannerImageSrc ? bannerImageSrc : BannerImage}
                alt="Banner Image"
              />
            </>
          )}
          <HeaderTitle className="company-info-header-title">
            <h5>{headerTitle}</h5>
            {headerDescription && (
              <div className="company-info-header-description body-1">
                {headerDescription}
              </div>
            )}
          </HeaderTitle>
        </div>
      </BannerWrapper>
      <SectionWrapper>{children}</SectionWrapper>
    </MainLayout>
  )
}
export default CustomCompanyInfo
