import BannerImage from "../../assets/images/hero-banner-image.png"
import WhoweareImageJGSummit from "../../assets/images/aboutus/JGSummit.jpg"
import AboutusHeroBanner from "../../assets/images/aboutus/about-us-banner-new.jpg"
import RLC from "../../assets/images/aboutus/RLC.png"
import AboutusRHR from "../../assets/images/aboutus/AboutUs-RHR.jpg"
import DTMC from "../../assets/images/aboutus/DTMC.png"
import ANR from "../../assets/images/aboutus/ANR.jpg"
import NustarFiliBrandPortfolio from "../../assets/images/aboutus/fili-nustar-new.jpg"
import CrowneBrandPortfolio from "../../assets/images/aboutus/Crowne-BrandPortfolio.png"
import HolidayInnBrandPortfolio from "../../assets/images/aboutus/HolidayInnBrandPortfolio.png"
import GrandSummitBrandPortfolio from "../../assets/images/aboutus/GrandSummitBrandPortfolio.png"
import SHRBrandPortfolio from "../../assets/images/aboutus/shr-new.jpg"
import GOHBrandPortfolio from "../../assets/images/aboutus/go-goplus.png"
import WestinBrandPortfolio from "../../assets/images/aboutus/The Westin Manila - Brand Portfolio - Oct 2023.png"
import EasyBooking from "../../assets/images/aboutus/easy-booking.png"
import AppPerks from "../../assets/images/aboutus/app-perks.png"
import Locations from "../../assets/images/aboutus/locations.png"
import DownloadNow from "../../assets/images/aboutus/download-now.png"
import DOTSafetySeal from "../../assets/images/aboutus/DOTSafetySeal.png"
import BrandPromisedOPBP from "../../assets/images/aboutus/IMG_8334.jpg"
import Mission from "../../assets/images/aboutus/mission-ops.png"
import VisionPhMap from "../../assets/images/aboutus/vision-ph-map.jpg"

// Who We Are Dummy Data
export const whoWeAreData1 = [
  {
    header_title: "<p>Who We Are</p>",
    text_content: "",
    link: "",
    pdf_url: "",
  },
]

export const whoWeAreData3 = [
  {
    photo_thumbnail_url: RLC,
    header_title: "<br>Robinsons Land Corporation</p>",
    text_content:
      "<p>Robinsons Land Corporation (RLC), one of the Philippines’ leading real estate companies, is involved in the development and operation of shopping malls and hotels. It is also one of the country’s most reputable developers of mixed-use properties, office buildings, residential condominiums, as well as land and residential housing developments, including socialized housing projects located in key cities and other urban areas nationwide.</p>",
    link: "https://www.robinsonsland.com/",
    pdf_url: "",
  },
]

export const whoWeAreData2 = [
  {
    photo_thumbnail_url: WhoweareImageJGSummit,
    header_title: "<p>JG Summit Holdings, Inc.</p>",
    text_content:
      "<p>JG Summit Holdings, Inc. is one of the leading conglomerates in the Philippines, with business interests in Food and Beverages, Real Estate and Hotels, Air Transportation, Banking and Petrochemicals, as well as core investments in Real Estate, Telecommunications and in Power Distribution.</p>",
    link: "https://www.jgsummit.com.ph/",
    pdf_url: "",
  },
]

export const whoWeAreData4 = [
  {
    photo_thumbnail_url: AboutusRHR,
    header_title: "<p>Robinsons Hotels and Resorts</p>",
    text_content: `<p>Robinsons Hotels and Resorts (RHR) is the hospitality arm of Robinsons Land Corporation (RLC) and is on the path to becoming the Philippines’ biggest and best hotel group with the widest variety of formats and brands and most diverse geographic reach.</p>
    <p>In the international deluxe category, it has the IHG managed Crowne Plaza Manila Galleria and Holiday Inn Manila Galleria, Dusit Thani Mactan Cebu, and The Westin Manila. For its homegrown brands, there are the Grand Summit, Summit Hotels and Resorts brands and the essential service hotel chain Go Hotels and Go Hotels Plus located in the Metro and in key cities nationwide. One of its major developments is in the NUSTAR Integrated Resort Complex which is currently home to two exceptional hotels, the NUSTAR Hotel Cebu (the only ultra-luxury hotel outside of Metro Manila) and Fili (a five-star resort offering genuine Filipino hospitality).</p>
    <p>Set on an island already renowned for its hospitality, each hotel has been designed to redefine 5-star luxury in accommodations, dining, and leisure.</p>`,
    link: "",
    pdf_url: "",
  },
]

// Brand Promise Dummy Data
export const brandPromiseData1 = [
  {
    header_title: "<p>Brand Promise</p>",
    text_content: "",
    link: "",
    pdf_url: "",
  },
]

export const brandPromiseData2 = [
  {
    photo_thumbnail_url: VisionPhMap,
    header_title:
      "<p>VISION <br><br> To be a world-class, Filipino hotel brand.</p>",
    text_content:
      "<p>Be the biggest and best hospitality company in the Philippines appealing to guests, colleagues, community and stakeholders.</p>",
    link: "",
    pdf_url: "",
  },
]

export const brandPromiseData3 = [
  {
    header_title: "<p>To create great hotel experiences.</p>",
    text_content:
      "<p>Provide true Filipino hospitality across all touch points by creating engaging guest experiences.</p>",
    link: "",
    pdf_url: "",
  },
]

export const brandPromiseData4 = [
  {
    photo_thumbnail_url: Mission,
    header_title:
      "<p> MISSION <br> <br> To create great hotel experiences.</p>",
    text_content:
      "<p>Provide true Filipino hospitality across all touch points by creating engaging guest experiences.</p>",
    link: "",
    pdf_url: "",
  },
]

// Brand Portfolio Dummy Data
export const brandPortfolioData1 = [
  {
    header_title: "<p>Brand Portfolio</p>",
    text_content:
      "<p>Whether for business or leisure, we have a diverse portfolio of International and Homegrown brands to choose from. Robinsons Hotels and Resorts strives to create genuine and heartfelt Filipino service culture to every kind of traveler.</p>",
    link: "",
    pdf_url: "",
  },
]

export const brandPortfolioData2 = [
  {
    photo_thumbnail_url: DTMC,
    header_title: "<p>Dusit Thani Mactan Cebu Resort</p>",
    text_content:
      "<p>A vibrant and inviting retreat blends Filipino warmth and Thai hospitality for a heavenly haven by the ocean at our hotel Mactan Cebu, Philippines resort.</p><p>On northern tip of the Punta Engaño Peninsula, Dusit Thani Mactan Cebu is easily accessible from the Mactan-Cebu International Airport by land and sea transport. Surrounded by tropical islands, natural, cultural and historic treasures are all within reach.</p><p>Guests relax in stylish rooms and suites, with gracious service, innovative cuisine, luxurious amenities and stunning views all on hand.</p>",
    link: "https://www.dusit.com/dusitthani-mactancebu/?utm_source=RHRWeb&utm_medium=KnowMore&utm_campaign=DTMC",
    pdf_url: "",
  },
]

export const brandPortfolioData3 = [
  {
    photo_thumbnail_url: CrowneBrandPortfolio,
    header_title: "<p>Crowne Plaza Manila Galleria</p>",
    text_content:
      "<p>Located at Ortigas Center, only a few minutes away from different multinational corporations and government offices, Crowne Plaza Manila Galleria newly renovated rooms provide a generous living space, designed to make sure that you are well-rested, energized, accomplished, and ready for a productive day. Crowne Plaza Manila Galleria boasts of an exclusive Club Lounge with signature cocktails and comfortable breathing space featuring a magnificent panoramic view of the city. Gastronomic journeys await our all-day dining restaurant, Seven Corners, and the award-winning Chinese restaurant, Xin Tian Di.The hotel also offers flexible event spaces with an approximate total of 34,000 sqm in size, ideal for small to large scale gatherings of up to 1,500 guests in its grand pillarless ballroom.</p>",
    link: "https://www.ihg.com/crowneplaza/hotels/gb/en/quezon-city/mnlcp/hoteldetail?utm_source=RHRWeb&utm_medium=KnowMore&utm_campaign=Crowne",
    pdf_url: "",
  },
]

export const brandPortfolioData4 = [
  {
    photo_thumbnail_url: HolidayInnBrandPortfolio,
    header_title: "<p>Holiday Inn Manila Galleria</p>",
    text_content:
      "<p>Holiday Inn Manila Galleria’s strategic central location is adjacent to Mandaluyong, Pasig, San Juan and Taguig which provide guests with quick access to Manila's top dining, shopping, leisure, tourist and business destinations.</p> <p>Stay in any of our 285 well-appointed, newly renovated guest rooms, and largest suites in Ortigas with complimentary Wi-Fi and other amenities to ensure a memorable experience with friends, family, and colleagues. Have fun by the pool, sit back and relax at the Spa, work out in our 24-hour Fitness Centre, spend quality time with your kids in our Outdoor Pool or relish a delicious breakfast at the restaurant. Best of all, Holiday Inn Manila Galleria is where Kids Stay and Eat Free.</p>",
    link: "https://www.ihg.com/holidayinn/hotels/us/en/pasig-city/mnlgl/hoteldetail?utm_source=RHRWeb&utm_medium=KnowMore&utm_campaign=HolidayInn",
    pdf_url: "",
  },
]

export const brandPortfolioData5 = [
  {
    photo_thumbnail_url: GrandSummitBrandPortfolio,
    header_title: "<p>Grand Summit Hotel</p>",
    text_content: `<p>Grand Summit is an award-winning, upscale brand of Robinsons Hotels and Resorts and a lifestyle hotel for local and international travelers who appreciate the unique culture and enjoy the comfort and unpretentious luxury with its locally inspired art and design. With its location right at the heart of General Santos City, the Grand is within key business districts and commercial centers, making it an ideal choice for both business and leisure travelers.</p>
    <p>The Grand embraces a pet-friendly policy, ensuring your furry friends are welcome during your stay. Their commitment to inclusivity extends to their Muslim guests, featuring dedicated prayer rooms, accessible washing areas for purification, prayer mats, and Qur'ans, allowing you to feel right at home.</p>
    <p>The hotel’s amenities include a fitness center, a rejuvenating spa, and a refreshing outdoor pool—perfect for unwinding after a busy day. For families, our kids’ room offers a safe and fun environment for younger guests to enjoy. Planning an event? The Grand’s versatile events venues are equipped to host everything from corporate meetings to social gatherings, providing the perfect backdrop for any occasion.</p>
    <p>An opulent setting for work, play, and celebrations, the Grand aims to be remembered not as a venue, but as a heartening feeling and delightful memory.</p>
    `,
    link: "https://grandsummithotels.ph/?utm_source=RHRWeb&utm_medium=KnowMore&utm_campaign=GS",
    pdf_url: "",
  },
]

export const brandPortfolioData6 = [
  {
    photo_thumbnail_url: SHRBrandPortfolio,
    header_title: "<p>Summit Hotels & Resorts</p>",
    text_content: `<p>Summit Hotels and Resorts is the midscale business and leisure hotels of Robinsons Hotels and Resorts. Nestled in key cities nationwide, the hotels’ strategic locations ensure you’re never far from where you need to be - from major attractions to business and commercial centers.</p>
    <p>Experience a restful stay in rooms designed with your comfort in mind. Enjoy modern amenities, including complimentary Wi-Fi, a fitness center, an outdoor or indoor pool, self check-in kiosks, pet-friendly rooms, and flexible meetings and events spaces perfect for hosting your corporate meetings to social celebrations.</p>
    <p>Savor delightful meals and treats at Cafe Summit, the hotel’s all-day dining restaurant. Need a quick pick-me-up? Grab a drink from RCoffee with a selection of specialty brews to energize your day.</p>    
    <p>Experience the perfect blend of comfort, convenience, and local charm. Book your stay today and discover why at Summit Hotels and Resorts, you can truly Stay Comfortably.</p>

    `,
    link: "https://www.summithotels.ph/?utm_source=RHRWeb&utm_medium=KnowMore&utm_campaign=SHR",
    pdf_url: "",
  },
]

export const brandPortfolioData7 = [
  {
    photo_thumbnail_url: GOHBrandPortfolio,
    header_title: "<p>Go Hotels & Go Hotels Plus</p>",
    text_content: `<p>Explore more of the Philippines with Go Hotels and Go Hotels Plus where you get quality service and accommodation at an affordable price. As the pioneering value essential hotel chain, guests are assured of their basic travel needs of a comfortable bed, secured facilities, and quality service within affordable rates.</p>
    <p>Go Hotels & Go Hotels Plus are your partners to enjoy, love, and explore the tropical islands of our country through its vast network of properties from Luzon to Mindanao.</p>
    `,
    link: "https://www.gohotels.ph/?utm_source=RHRWeb&utm_medium=KnowMore&utm_campaign=GOH",
    pdf_url: "",
  },
]

export const brandPortfolioData8 = [
  {
    photo_thumbnail_url: NustarFiliBrandPortfolio,
    header_title: "<p>NUSTAR Hotel & Fili Hotel Cebu</p>",
    text_content: `<p><strong>Fili Hotel</strong> is the first and only Filipino 5-star hotel brand, developed by RHR to reflect the unique hospitality ethos of the country, with its flagship at NUSTAR Resort & Casino, Cebu. It brings together the finest hotel offerings with modern Filipino elements, celebrating Filipino craftsmanship. The 379 guest rooms and suites feature local contemporary decor and breathtaking views of the sea or city skyline. The adventure includes a gastronomic journey from exquisite Filipino cuisine to international dining concepts. 
Pride for the local heritage, culture, and the global charm of Filipino hospitality is reflected in genuine care, friendliness, and efficiency of service. At the heart of the pulsating NUSTAR Integrated Resort, Fili is a haven inspired by tropical sunlight, lush greens, and sea breeze; everyone is embraced by Filipino warmth.</p>
<p>The second hotel <strong>NUSTAR Hotel</strong> promises to be the only ultra-luxury hotel outside of Metro Manila designed for the VIP clientele of NUSTAR Casino. The hotels have versatile meetings and events spaces at the NUSTAR Convention Center, which are perfect settings for the most remarkable of occasions. Touted to be the largest events venue in Visayas & Mindanao, the Convention Center spans a total area of 2,449 square meters with a ceiling height of six meters, capable of accommodating elaborate installations and dynamic event setups from corporate to social functions.
</p>

`,
    link: "https://nustar.ph/?utm_source=RHRWeb&utm_medium=KnowMore&utm_campaign=NUSTAR",
    pdf_url: "",
  },
]

export const brandPortfolioData9 = [
  {
    photo_thumbnail_url: WestinBrandPortfolio,
    header_title: "<p>The Westin Manila</p>",
    text_content: `<p>The Westin Manila boasts its prime location for business and leisure travelers, in the Ortigas Business District in Manila, just 13km from Ninoy Aquino International Airport. </p><p>This multi-story hotel promises heavenly experiences, from its guest rooms, amenities, fitness studio, bar and restaurant, and event spaces.</p>`,
    link: "https://www.marriott.com/hotels/travel/mnlwp-the-westin-manila-sonata-place/?utm_source=RHRWeb&utm_medium=KnowMore&utm_campaign=Westin",
    pdf_url: "",
  },
]

// Feel Safe at RHR Dummy Data
export const feelSafeData = [
  {
    title: "<p>Feel Safe at RHR</p>",
    short_description: `<p>The tourism industry in the Philippines faced many challenges during the pandemic. Amidst these trying times, RHR has found an opportunity to fulfill our mission to provide world-class hospitality for everyone - from brave front liners to business travelers, staycationers, and more! No matter what your purpose of staying, you have a home with us at RHR.</p>
    <p>At RHR, you come first. Your health and safety are our top priority. That is why we elevated our cleanliness protocols to make sure you are well taken care of.</p>`,
    redirect_link: "",
    pdf_url: "",
  },
  {
    photo_url: EasyBooking,
    title: "<p>The Robinsons Hotels Mobile App</p>",
    short_description:
      "<p>With the RHR Mobile App, get to book hassle-free with exclusive deals at one of the best hotel groups with the widest variety of brands and most diverse geographic reach.</p> <p style='margin-bottom: 0'>Hotel booking is made easy with these steps:</p><span style='display: block'>1. Choose a hotel from 24 RHR properties</span><span style='display: block'>2. Simply select your stay dates and preferred room and tap book!</span><span style='display: block'>3. Choose to pay at hotel or advance payment.</span>",
    redirect_link: "",
    pdf_url: "",
  },
  {
    photo_url: AppPerks,
    title: "<p>Perks</p>",
    short_description:
      "<p>Plan your stay with us and avail <strong>exclusive and the best discounts</strong> at either Grand Summit, Summit Hotels, Go Hotels Plus, and Go Hotels properties nationwide.</p><p>We ensure that your stay is not just pocket-friendly but also <strong>rewarding</strong>. Bookings made direct through the Robinsons Hotels app are eligible to earn Go Rewards points. Pay with Go Rewards points is also available.</p><p>For <strong>convenience</strong>, guests with bookings paid in advance through the app may proceed to the Self Check-In kiosk stationed near the Front Desk and conveniently scan the QR code provided in the confirmation email, tap to create their keycard and easily head to their room. </p>",
    redirect_link: "",
    pdf_url: "",
  },
  {
    photo_url: Locations,
    title: "",
    short_description:
      "<p>Our properties are located in key areas nationwide:<p><ul><li>Bacolod</li><li>Butuan</li><li>Cebu</li><li>Dumaguete</li><li>General Santos</li><li>Iloilo</li><li>Iligan</li><li>Lanang-Davao</li><li>Metro Manila</li><li>Naga</li><li>Puerto Princesa</li><li>Tacloban</li><li>Tuguegarao</li></ul>",
    redirect_link: "",
    pdf_url: "",
  },
  {
    photo_url: DownloadNow,
    title: "",
    short_description:
      "<p>Click the button below or scan the QR code to download the Robinsons Hotels Mobile App now.</p>",
    redirect_link: "https://uqr.to/1vmfn",
    pdf_url: "",
    buttonTitle: "Download Now",
  },
  {
    photo_url: DOTSafetySeal,
    title: "<p>DEPARTMENT OF TOURISM - SAFETY SEAL</p>",
    short_description:
      "<p>The Safety Seal is a seal issued by government agencies, affirming that an establishment has been found compliant with the minimum public health standards set by the government, including the adoption or integration of the StaySafe.ph in contact tracing applications.</p><p>We continue to demonstrate our commitment to the health of tourists and locals alike with the Safety Seals we have acquired in our properties.</p>",
    redirect_link: "https://safetyseal.dti.gov.ph/",
    pdf_url: "",
  },
]

export const bannerImages = [
  {
    url: AboutusHeroBanner,
  },
]

export const hashValue = [
  {
    hash: "#who-we-are",
    id: "1",
  },
  {
    hash: "#brand-promise",
    id: "2",
  },
  {
    hash: "#brand-portfolio",
    id: "3",
  },
  {
    hash: "#robinsons-hotels-mobile-app",
    id: "4",
  },
]
