import React, { useEffect, useState } from "react"
import Seo from "../../components/seo"
import { Tabs } from "antd"

import { Row, Col } from "antd"

import CustomCompanyInfo from "../../components/CustomCompanyInfo/CustomCompanyInfo"
import {
  CenterALign,
  LeftSide,
  RightSide,
} from "../../components/Brand/SectionsTemplate"

import "./AboutUs.scss"

import {
  whoWeAreData1,
  whoWeAreData2,
  whoWeAreData3,
  whoWeAreData4,
  brandPortfolioData1,
  brandPortfolioData2,
  brandPortfolioData3,
  brandPortfolioData4,
  brandPortfolioData5,
  brandPortfolioData6,
  brandPortfolioData7,
  brandPortfolioData8,
  brandPortfolioData9,
  brandPromiseData1,
  brandPromiseData2,
  brandPromiseData3,
  brandPromiseData4,
  feelSafeData,
  bannerImages,
  hashValue,
} from "./constants"

const { TabPane } = Tabs

const AboutPage = ({ pageContext }) => {
  const [hash, setHash] = useState<string>("1")

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("hashchange", hashHandler, false)
      tabChange(window.location.hash)
    }
  }, [])

  const tabChange = hash => {
    if (isNaN(hash)) {
      const value = hashValue.filter(value => {
        if (value.hash === hash) {
          return value
        }
      })
      return setHash(value[0].id)
    }
    return setHash(hash)
  }

  const hashHandler = () => {
    if (typeof window !== "undefined") {
      tabChange(window.location.hash)
    }
  }

  return (
    <CustomCompanyInfo
      pageContext={pageContext}
      pageTitle="about-us inside-pages"
      headerTitle="About Us"
      bannerImages={bannerImages}
      //headerDescription="Robinsons Hotels and Resorts (RHR) is on the path to becoming the Philippines’ biggest and best hotel group with the widest variety of formats and brands and most diverse geographic reach – with 24 properties currently under its wing as of 2021."
    >
      <Seo
        title="About Us"
        description="Robinsons Hotels and Resort. No matter what your purpose of staying, you have a home with us. Find the perfect hotel."
        lang="en"
      />
      <div className="about-us-wrapper">
        <Tabs activeKey={hash} onChange={e => tabChange(e)}>
          <TabPane tab="Who We Are" key="1">
            <div className="who-we-are-content-wrapper">
              <CenterALign details={whoWeAreData1} />
              <RightSide details={whoWeAreData3} />
              <LeftSide details={whoWeAreData2} />
              <RightSide details={whoWeAreData4} />
            </div>
          </TabPane>
          <TabPane tab="Brand Promise" key="2">
            <div className="brand-promise-content-wrapper">
              <CenterALign details={brandPromiseData1} />
              <LeftSide details={brandPromiseData2} />

              <RightSide details={brandPromiseData4} />
            </div>
          </TabPane>
          <TabPane tab="Brand Portfolio" key="3">
            <Row className="brand-portfolio-content-wrapper">
              <CenterALign details={brandPortfolioData1} />
              <LeftSide details={brandPortfolioData2} />
              <RightSide details={brandPortfolioData3} />
              <LeftSide details={brandPortfolioData4} />
              <RightSide details={brandPortfolioData5} />
              <LeftSide details={brandPortfolioData6} />
              <RightSide details={brandPortfolioData7} />
              <LeftSide details={brandPortfolioData8} />
              <RightSide details={brandPortfolioData9} />
            </Row>
          </TabPane>
          <TabPane tab="Robinsons Hotels Mobile App" key="4">
            <div className="feel-safe-content-wrapper">
              {/* <CenterALign details={feelSafeData[0]} /> */}
              <RightSide details={feelSafeData[1]} />
              <LeftSide details={feelSafeData[2]} />
              <RightSide details={feelSafeData[3]} />
              <LeftSide details={feelSafeData[4]} />
              {/* <LeftSide details={feelSafeData[5]} /> */}
            </div>
          </TabPane>
        </Tabs>
      </div>
    </CustomCompanyInfo>
  )
}

export default AboutPage
